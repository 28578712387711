<script setup lang="ts">
import { useContact } from '@/composables/contact'
import { useMenu } from '@/composables/menu'
import useAppStore from '@/stores/app'
import useAuthStore, { AuthIntent } from '@/stores/auth'
import { AppSection } from 'lilasia-ui'
import { useI18n } from 'vue-i18n'

const { openMail, openPhone, openWhatsApp } = useContact()
const { companyMenu, legalMenu } = useMenu()
const { t } = useI18n()

const appStore = useAppStore()
const authStore = useAuthStore()

const showMerchantRegistration = () => {
  authStore.showAuthModal(AuthIntent.MerchantSignup)
}
</script>

<template>
  <AppSection class="bg-black-100 py-24 md:py-48">
    <div class="flex flex-col gap-40 md:!flex-row lg:!flex-row">
      <div class="flex flex-col gap-12">
        <img
          src="/images/common/lilasia-logo-white.svg"
          alt="Lilasia"
          class="h-[28px] w-[88px]"
        />
        <div class="text-nowrap leading-32 text-black-30">
          &copy;{{ new Date().getFullYear() }} PT Teknologi Perdagangan Lilasia. <br />
          All rights reserved.
        </div>

        <div class="flex flex-row gap-8">
          <a
            class="text-black-30 hover:text-yellow-100"
            aria-label="Lilasia on WhatsApp"
            role="button"
            @click="openWhatsApp"
          >
            <img
              src="/images/common/social-media-icons/whatsapp.svg"
              width="20"
              alt="WhatsApp"
            />
          </a>

          <a
            href="https://instagram.com/lilasia.id/"
            target="_blank"
            class="text-black-30"
            aria-label="Lilasia on Instagram"
          >
            <img
              src="/images/common/social-media-icons/instagram.svg"
              width="20"
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/pt-teknologi-perdagangan-lilasia/"
            target="_blank"
            class="text-black-30 hover:text-yellow-100"
            aria-label="Lilasia on LinkedIn"
          >
            <img
              src="/images/common/social-media-icons/linkedin.svg"
              width="20"
              alt="LinkedIn"
              class="text-black-30 hover:text-yellow-100"
            />
          </a>
        </div>
      </div>

      <div
        class="grid gap-48 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-2 lg:grid-rows-2 xl:grid-cols-4 xl:grid-rows-1"
      >
        <div class="md: flex flex-col gap-12 lg:col-span-1">
          <span class="text-20 font-600 text-white">{{ t('get_in_touch') }}</span>
          <a
            class="text-black-30 hover:text-yellow-100"
            role="button"
            @click="openMail"
          >
            {{ t('send_an_email') }}
          </a>
          <a
            class="text-black-30 hover:text-yellow-100"
            role="button"
            @click="openPhone"
          >
            {{ appStore.support.phone }}
          </a>
        </div>

        <div class="flex flex-col gap-12 lg:col-span-1">
          <span class="text-20 font-600 text-white">{{ t('business_solution') }}</span>

          <div
            v-if="!authStore.isMerchant"
            class="text-black-30 hover:text-yellow-100"
            role="button"
            @click="showMerchantRegistration"
          >
            {{ t('apply_as_merchant') }}
          </div>

          <RouterLink :to="{ name: 'merchant' }">
            <div class="text-black-30 hover:text-yellow-100">
              {{ t('lilasia_for_merchant') }}
            </div>
          </RouterLink>

          <RouterLink :to="{ name: 'wholesale' }">
            <div class="text-black-30 hover:text-yellow-100">
              {{ t('lilasia_commodity_sourcing') }}
            </div>
          </RouterLink>
        </div>

        <div class="flex flex-col gap-12 lg:col-span-1">
          <span class="text-20 font-600 text-white">{{ t('company') }}</span>
          <div
            v-for="(menu, companyMenuIndex) in companyMenu"
            :key="companyMenuIndex"
            class="text-black-30 hover:text-yellow-100"
          >
            <RouterLink
              v-if="menu.to"
              :to="menu.to"
              >{{ menu.label }}</RouterLink
            >
            <a
              v-else
              :href="menu.href"
              target="_blank"
            >
              {{ menu.label }}
            </a>
          </div>
        </div>

        <div class="flex flex-col gap-12 lg:col-span-1">
          <span class="text-20 font-600 text-white">{{ t('legal') }}</span>
          <div
            v-for="(menu, legalMenuIndex) in legalMenu"
            :key="legalMenuIndex"
            class="text-black-30 hover:text-yellow-100"
          >
            <RouterLink :to="menu.to">{{ menu.label }}</RouterLink>
          </div>
        </div>
      </div>
    </div>
  </AppSection>
</template>
