import AppLayout from '@/layouts/AppLayout.vue'
import MerchantLayout from '@/layouts/MerchantLayout.vue'
import useAuthStore from '@/stores/auth'
import { useDialog } from 'lilasia-ui'
import { createRouter, createWebHistory } from 'vue-router'
import ProductGalleryLayout from './layouts/ProductGalleryLayout.vue'
import SearchSuggestionLayout from './layouts/SearchSuggestionLayout.vue'

const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/pages/AboutPage.vue')
      },
      {
        path: 'c/:slug',
        name: 'categories.show',
        props: (route: { params: { slug: string } }) => ({ slug: route.params.slug }),
        component: () => import('@/pages/CategoryDetailsPage.vue')
      },
      {
        path: 'checkout',
        name: 'checkout',
        meta: { requiresAuth: true },
        component: () => import('@/pages/CheckoutPage.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/pages/ContactPage.vue')
      },
      {
        path: 'error404',
        name: 'error404',
        component: () => import('@/pages/Error404Page.vue')
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/pages/PrivacyPolicyPage.vue')
      },
      {
        path: 'products/:id/:slug',
        name: 'products.show',
        component: () => import('@/pages/ProductDetailsPage.vue'),
        props: (route: { params: { id: string; slug: string } }) => ({
          id: Number(route.params.id),
          slug: route.params.slug
        })
      },
      {
        path: 'promotions/:slug',
        name: 'promotions.show',
        props: (route: { params: { slug: string } }) => ({ slug: route.params.slug }),
        component: () => import('@/pages/PromotionDetailsPage.vue')
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/pages/SearchPage.vue')
      },
      {
        path: 'shops/:slug',
        name: 'shops.show',
        props: (route: { params: { slug: string } }) => ({ slug: route.params.slug }),
        component: () => import('@/pages/ShopDetailsPage.vue')
      },
      {
        path: 'terms-condition',
        name: 'terms-condition',
        component: () => import('@/pages/TermsConditionPage.vue')
      },
      {
        path: 'account',
        component: () => import('@/pages/account/AccountPage.vue'),
        redirect: { name: 'account.edit-profile' },
        meta: { requiresAuth: true },
        children: [
          {
            path: 'addresses',
            name: 'account.addresses',
            component: () => import('@/pages/account/AddressesPage.vue')
          },
          {
            path: 'browse-history',
            name: 'account.browse-history',
            component: () => import('@/pages/account/BrowseHistoryPage.vue')
          },
          {
            path: 'change-password',
            name: 'account.change-password',
            component: () => import('@/pages/account/ChangePasswordPage.vue')
          },
          {
            path: 'edit-profile',
            name: 'account.edit-profile',
            component: () => import('@/pages/account/EditProfilePage.vue')
          },
          {
            path: 'order-history',
            name: 'account.order-history',
            component: () => import('@/pages/account/OrderHistoryPage.vue')
          },
          {
            path: 'wishlist',
            name: 'account.wishlist',
            component: () => import('@/pages/account/WishlistPage.vue')
          },
          {
            path: 'process',
            name: 'account.process',
            component: () => import('@/pages/account/OrderHistoryPage.vue')
          },
          {
            path: 'refund',
            name: 'account.refund',
            component: () => import('@/pages/account/OrderHistoryPage.vue')
          },
          {
            path: 'completed',
            name: 'account.completed',
            component: () => import('@/pages/account/OrderHistoryPage.vue')
          }
        ]
      },
      {
        path: '/:pathMatch(.*)',
        name: 'not-found',
        component: () => import('@/pages/404Page.vue')
      },
      {
        path: 'error403',
        name: 'error403',
        component: () => import('@/pages/403Page.vue')
      },
      {
        path: 'something-went-wrong',
        name: 'something-went-wrong',
        component: () => import('@/pages/SomethingWentWrong.vue')
      },
      {
        path: 'password/reset',
        name: 'password-reset',
        component: () => import('@/pages/HomePage.vue')
      }
    ]
  },
  {
    path: '/search-suggestions',
    component: SearchSuggestionLayout,
    children: [
      {
        path: '',
        name: 'search.suggestions',
        component: () => import('@/pages/SearchSuggestionPage.vue')
      }
    ]
  },
  {
    path: '/products/:id/:slug/gallery',
    component: ProductGalleryLayout,
    children: [
      {
        path: '',
        name: 'product.gallery',
        component: () => import('@/pages/ProductGalleryPage.vue'),
        props: (route: { params: { id: string; slug: string } }) => ({
          id: Number(route.params.id),
          slug: route.params.slug
        })
      }
    ]
  },
  {
    path: '/m',
    name: 'merchant.centre',
    component: MerchantLayout,
    redirect: { name: 'merchant.manage-products' },
    meta: { requiresAuth: true, isMerchant: true },
    children: [
      {
        path: 'manage-products',
        name: 'merchant.manage-products',
        component: () => import('@/pages/merchant/ManageProductsPage.vue')
      },
      {
        path: 'shop-settings',
        name: 'merchant.shop-settings',
        component: () => import('@/pages/merchant/ShopSettingsPage.vue')
      }
    ]
  },
  {
    path: '/wholesale',
    name: 'wholesale',
    component: () => import('@/pages/WholeSaleLandingPage.vue')
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('@/pages/MerchantLandingPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior() {
    return { top: 0, left: 0 }
  },
  routes
})

router.beforeEach(async (to, _from, next) => {
  const { hideDialog } = useDialog()
  const authStore = useAuthStore()

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({ name: 'home' })
  }

  if (to.meta.isMerchant && !authStore.isMerchant) {
    return next({ name: 'home' })
  }

  hideDialog()

  next()
})

export default router
