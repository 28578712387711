import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const useAppStore = defineStore('app', () => {
  const env = ref(import.meta.env.VITE_APP_ENV)
  const testMode = ref(false)
  const version = ref(__APP_VERSION__)
  const name = ref(import.meta.env.VITE_APP_NAME)
  const storagePrefix = ref(`__lilasia_storage_${__APP_VERSION__}_`)
  const paymentStrategy = ref(null)
  const isLoading = ref(false)
  const loggingIn = ref(false)
  const registering = ref(false)
  const showMobileMenu = ref(false)
  const showNavbar = ref(true)

  const currency = useLocalStorage(
    `${storagePrefix.value}currency`,
    Object.assign({}, { selected: 'USD', default: null as string | null })
  )

  const exchangeRates = ref([] as Rate[])
  const selectedRate = ref({} as Rate)
  const paypal = ref({
    clientId:
      'live' === import.meta.env.VITE_PAYPAL_MODE
        ? import.meta.env.VITE_PAYPAL_LIVE_CLIENT_ID
        : import.meta.env.VITE_PAYPAL_SANDBOX_CLIENT_ID,
    components: ['buttons'],
    currency: 'USD',
    percentageFee: Number(import.meta.env.VITE_PAYPAL_PERCENTAGE_FEE),
    fixedFee: Number(import.meta.env.VITE_PAYPAL_FIXED_FEE)
  })

  const bayarind = ref({
    currency: 'IDR',
    percentageFee: Number(import.meta.env.VITE_BAYARIND_PERCENTAGE_FEE),
    fixedFee: Number(import.meta.env.VITE_BAYARIND_FIXED_FEE)
  })

  const support = ref({
    email: import.meta.env.VITE_SUPPORT_EMAIL,
    phone: import.meta.env.VITE_SUPPORT_PHONE
  })

  const whatsAppLink = computed(() => {
    // strip non-numeric characters
    const phoneNumber = support.value.phone.replace(/\D/g, '')
    return `https://wa.me/${phoneNumber}/`
  })

  const openWhatsApp = async () => {
    // await trackContact()
    window.open(whatsAppLink.value, '_blank')
  }

  const telLink = computed(() => {
    // Strip non-numeric characters
    const phoneNumber = support.value.phone.replace(/\D/g, '')
    return `tel:${phoneNumber}`
  })

  const openTel = () => {
    // await trackContact()
    window.open(telLink.value, '_self')
  }

  return {
    env,
    testMode,
    version,
    name,
    storagePrefix,
    paymentStrategy,
    isLoading,
    loggingIn,
    registering,
    showMobileMenu,
    showNavbar,
    currency,
    exchangeRates,
    selectedRate,
    paypal,
    bayarind,
    support,
    whatsAppLink,
    openWhatsApp,
    openTel
  }
})

export default useAppStore
