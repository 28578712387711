import { useDialog } from 'lilasia-ui'
import { defineStore } from 'pinia'
import { defineAsyncComponent, ref } from 'vue'

const useInquiryStore = defineStore('inquiry', () => {
  const { showDialog } = useDialog()

  const url = ref<string>()

  const showInquiryModal = () => {
    showDialog(defineAsyncComponent(() => import('@/components/common/MakeInquiryModal.vue')))
  }

  return {
    url,
    showInquiryModal
  }
})

export default useInquiryStore
